import React, { useState, useEffect } from "react"

import projectItems from "./projectSpec"
import { useLocation } from "react-router"
import Carousel from "./Carousel"
export const ProjectsPage = ({ darkMode }) => {
  window.scrollTo(0, 0)

  const [currentIndex, setCurrentIndex] = useState(0)
  // const [prevIndex, setPrevIndex] = useState(0)
  const { state } = useLocation()
  useEffect(() => {
    const firstIndex =
      state && projectItems.findIndex((obj) => obj.ref === state.goToFilter)
    console.log("use effect", firstIndex)
    firstIndex && setCurrentIndex(firstIndex)
  }, [])
  const onChange = (e) => setCurrentIndex(parseInt(e || 0))

  return (
    <div
      className={`min-width-projects project-container ${
        darkMode && "background-dark"
        }`}
    >
      <div className="header-about-us">
        <p
          className={`about-us-title-projects ${darkMode && "p-color-white"} `}
        >
          PROJECTS
        </p>
      </div>
      <Carousel
        onChange={onChange}
        currentIndex={currentIndex}
        darkMode={darkMode}
        setCurrentIndex={setCurrentIndex}
        projectItems={projectItems}
      />
    </div>
  )
}
