const elasticity = 0.001
const speed = 0.5 //0.3
export const getConfigCenter = (size) => {
  const generelSettings = {
    wrapperContainer: "woman-container",
    addInteraction: true,
    id: "blob-center",
    radiusX: 75,
    radiusY: 75,
    color: "#ff6666",
    elasticity: elasticity,
    speed: speed,
  }
  switch (size) {
    case "small":
      return { ...generelSettings, xOffset: 1.28, yOffset: 3.158 }
    case "medium":
      return { ...generelSettings, xOffset: 1.25, yOffset: 3.158 }
    case "big":
      return { ...generelSettings, xOffset: 1.28, yOffset: 3.158 }
    default:
      return { ...generelSettings, xOffset: 1.28, yOffset: 3.158 }
  }
}

export const getConfigRight = (size) => {
  const generelSettings = {
    wrapperContainer: "woman-container",
    addInteraction: true,
    id: "blob-right",
    radiusX: 60,
    radiusY: 60,
    color: "#ffcc33",
    elasticity: elasticity,
    speed: speed,
  }

  switch (size) {
    case "small":
      return { ...generelSettings, xOffset: 1.55, yOffset: 1.99 }
    case "medium":
      return { ...generelSettings, xOffset: 1.55, yOffset: 1.99 }
    case "big":
      return { ...generelSettings, xOffset: 1.55, yOffset: 1.99 }
    default:
      return { ...generelSettings, xOffset: 1.55, yOffset: 1.99 }
  }
}

export const getConfigLeft = (size, darkMode = false) => {
  //   console.log("GETFACKING CONGIG?", darkMode, darkMode ? "#6FBE95" : "#222229")
  const generelSettings = {
    wrapperContainer: "woman-container",
    addInteraction: true,
    id: "blob-left",
    radiusX: 100,
    radiusY: 100,
    color: darkMode ? "#6FBE95" : "#222229",
    elasticity: elasticity,
    speed: speed,
  }
  switch (size) {
    case "small":
      return { ...generelSettings, xOffset: 0.84, yOffset: 0.88 }
    case "medium":
      return { ...generelSettings, xOffset: 0.84, yOffset: 0.88 }
    case "big":
      return { ...generelSettings, xOffset: 0.77, yOffset: 0.97 }
    default:
      return { ...generelSettings, xOffset: 0.84, yOffset: 0.88 }
  }
}
export const getConfigBottom = (size) => {
  const generelSettings = {
    wrapperContainer: "woman-container",
    addInteraction: true,
    id: "blob-bottom",
    radiusX: 70,
    radiusY: 25,
    color: "#333366",
    elasticity: 0.0008,
    speed: 0.2,
  }
  switch (size) {
    case "small":
      return { ...generelSettings, xOffset: 1.26, yOffset: 3.99 }
    case "medium":
      return { ...generelSettings, xOffset: 1.26, yOffset: 4.1 }
    case "big":
      return { ...generelSettings, xOffset: 1.26, yOffset: 4.3 }
    default:
      return { ...generelSettings, xOffset: 1.26, yOffset: 3.99 }
  }
}
