import React from "react"
import ReasonToJoin from "components/ReasonToJoin"
import "./ReasonsToJoin.css"
const ResonsToJoin = ({ title, reasons, darkMode }) => {
  return (
    <div className="reasons-to-join-wrapper">
      <div className="margin-top-42px header-about-us">
        <p className={`reasons-title font-size-32px  about-us-title ${darkMode && "p-color-white"}`}>
          {/* <p
          className={` font-weight-links line-height ${
            darkMode ? "p-color-white" : "p-color"
          }`}
        > */}
          {title}
        </p>
      </div>
      <div className="reasons-to-join-content">
        {reasons.map((reason) => (
          <ReasonToJoin reason={reason} darkMode={darkMode} />
        ))}
      </div>
    </div>
  )
}
export default ResonsToJoin
