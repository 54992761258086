import React from "react"
import { Link } from "react-router-dom"
import LadyBody from "assets/images/ladyBody.svg"
import LadyArms from "assets/images/ladyArms.svg"
import Eyes from "./Eyes"
const Woman = ({ darkMode, interpPupil, interpEye, interpBg }) => (
  <div id="woman-container" class="woman-container">
    <Eyes
      id={"left-eye"}
      interpPupil={interpPupil}
      interpEye={interpEye}
      interpBg={interpBg}
    />
    <Eyes
      id={"right-eye"}
      interpPupil={interpPupil}
      interpEye={interpEye}
      interpBg={interpBg}
    />
    <img id="lady-body" src={LadyBody} alt="lady-body" class="lady-body"></img>
    <img src={LadyArms} alt="bottom-blob" class="lady-arms"></img>
    <div>
      <p
        className={`${
          darkMode ? "home-about-text-dark" : "home-about-text-light"
        } link left-blob-link`}
      >
        <br />
        We build your future innovations
      </p>
    </div>
    <div>
      <Link to="./projects">
        <span className="link top-blob-link">
          <p>Projects</p>
        </span>
      </Link>
    </div>
    <Link to="./work">
      <span className="link bottom-blob-link ">
        <p>Work with us</p>
      </span>
    </Link>
    <Link to="./about">
      <span className="link center-blob-link">
        <p>About</p>
      </span>
    </Link>
  </div>
)
export default Woman
