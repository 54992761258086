import React, { useState, useEffect, useCallback } from "react"

import Planet1 from "assets/images/PlanetEMBED.svg"
import Planet2 from "assets/images/Planet2.1.svg"
import Planet3 from "assets/images/Planet4.1_Moon.svg"

import { Blorre } from "components/Blob"
import { useSpring, animated as a, interpolate } from "react-spring"
import {
  getConfigBottom,
  getConfigLeft,
  getConfigCenter,
  getConfigRight,
} from "./blobSpec"
import Contact from "./Contact"
import Woman from "./Woman"
var resizeTimer

const AlternativeHomepage = ({ darkMode }) => {
  window.scrollTo(0, 0)

  const [resizedFlip, setResizedFLip] = useState(false)
  const [configCenter, setConfigCenter] = useState(getConfigCenter("big"))
  const [configRight, setConfigRight] = useState(getConfigRight("big"))
  const [configLeft, setConfigLeft] = useState(getConfigLeft("big", darkMode))
  const [configBottom, setConfigBottom] = useState(getConfigBottom("big"))
  const [oldWidth, setOldWidth] = useState(document.documentElement.clientWidth)
  let b1 = Blorre(configCenter, resizedFlip)
  let b2 = Blorre(configRight, resizedFlip)
  let b3 = Blorre(configLeft, resizedFlip)
  let b4 = Blorre(configBottom, resizedFlip)

  useEffect(() => {
    // console.log("ALTERNATIVE HOMEPAGE RERENDER?")
    // console.log("####################   ", b3.color)
    setConfigLeft(getConfigLeft("big", darkMode))
    // console.log("------------------__", configLeft.color)
    b3.color = configLeft.color
  }, [darkMode])

  function displayWindowSize() {
    // Get width and height of the window excluding scrollbars

    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(function () {
      console.log("----------------TIMEOUT?")

      // Run code here, resizing has "stopped"
      var w = document.documentElement.clientWidth
      var h = document.documentElement.clientHeight
      if (oldWidth !== w && w < 13000) {
        console.log(w, oldWidth)
        setConfigCenter(getConfigCenter("medium"))
        setConfigRight(getConfigRight("medium"))
        setConfigLeft(getConfigLeft("medium", darkMode))
        setConfigBottom(getConfigBottom("medium"))
        setOldWidth(w)
        setResizedFLip(!resizedFlip)
        // let b1 = Blorre(configCenter, resizedFlip)
      }
    }, 250)
  }

  // Attaching the event listener function to window's resize event
  window.addEventListener("resize", displayWindowSize)

  const [{ st, xy }, set] = useSpring(() => ({ st: 0, xy: [0, 0] }))
  const interpBg = xy.interpolate(
    (x, y) =>
      `perspective(400px) rotateY(${x / 60}deg) rotateX(${
      -y / 60
      }deg) translate3d(-50%, -50%, 0)`
  )
  const interpEye = interpolate(
    [st, xy],
    (o, xy) =>
      `translate(${xy[0] / 30 + 157},${xy[1] / 30 + 80 + o / 2}) scale(0.8)`
  )

  const interpPupil = interpolate(
    [st, xy],
    (o, xy) => `translate(${xy[0] / 25},${xy[1] / 25 + -10 + o / 8})`
  )
  const onMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      set({ xy: [x - window.innerWidth / 2, y - window.innerHeight / 2] }),
    []
  )
  const onScroll = useCallback((e) => set({ st: e.target.scrollTop / 30 }), [])

  return (
    <>
      <div
        className={`home-container-alternative ${
          darkMode && "background-dark"
          }`}
      >
        <div
          class="margin-y-woman-container container"
          onMouseMove={onMove}
          onScroll={onScroll}
        >
          <Woman
            interpPupil={interpPupil}
            interpEye={interpEye}
            interpBg={interpBg}
            darkMode={darkMode}
          />
        </div>
        <Contact darkMode={darkMode} />
      </div>

      {darkMode && (
        <>
          <img class="planet-1" src={Planet1} alt="planet-1" />
          <img class="planet-2" src={Planet2} alt="planet-2" />
          <img class="planet-3" src={Planet3} alt="planet-3" />
        </>
      )}
    </>
  )
}

export default AlternativeHomepage
