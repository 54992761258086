const body = ""
const subject = "Hi I am looking for a job"
const subjectContact = "Collaborate on amazing stuff with Growbotics"
export const INVESTMENT = "investment"
export const ADDRESS_LINK =
  "https://www.google.com/maps/place/Br%C3%A4nnkyrkagatan+35,+118+22+Stockholm/@59.3193614,18.0600846,17z/data=!3m1!4b1!4m5!3m4!1s0x465f77e425b7116f:0x6445ef6256e6120a!8m2!3d59.3193614!4d18.0622733"
export const EMAIL_PETTER = `mailto:petter@growbotics.se?cc=mikaela@growbotics.se&subject=${subject}&body=Hi Petter!${body} `
export const EMAIL_MIKAELA = `mailto:mikaela@growbotics.se?cc=petter@growbotics.se&subject=${subject}&body=Hi Mikaela! ${body} `
export const EMAIL_CONTACT = `mailto:mikaela@growbotics.se?cc=petter@growbotics.se&subject=${subjectContact}&body=Hi Mikaela! ${body} `

export const green = "#6FBE95"

export const blue = "#333366"

export const yellow = "#FFCC33"

export const red = "#FF6666"

export const DARK_MODE_TIME_DAY = 7
export const DARK_MODE_TIME_NIGHT = 17
