import buildProd from "assets/animations/buildproductnow.json"
import reason2 from "assets/animations/blob.json"
import reason3 from "assets/animations/animation_reason3.json"

import { INVESTMENT, ADDRESS_LINK } from "assets/constants"
const reasons = [
  {
    name: "Build our product, please.",
    description: "We build your product or venture from idea to product. We offer our clients expertise in modern software engineering, cloud architectures, AI and UX/UI that actually works for your business case. You can contract the full time or a senior member and we can build it completely or integrate into your current team.",
    linkText: "See Examples Of Work!",
    link: "/projects",
    animation: buildProd,
  },
  {
    name: "Create an innovation.",
    description: "The core of our belief. The reason for waking up. Our Innovation Fund. Do you have a great idea that needs seed investment? Just give us a call and you can be on track in a matter of days if we believe you and your idea is awesome. We will coach and guide you into convincing both us and external investors of the potential in your idea.",
    linkText: "See Examples Of Investment",
    link: "/projects",
    animation: reason2,
    ref: INVESTMENT,
  },
  {
    // name: "FINNS-I-SJÖN W. THE TEAM.",
    name: "Make yourself indispensible.",
    description:
      "We are always looking for new engineers and passionate humans that wants to join and contribute to the journey. At Growbotics you will get the opportunity to contribute, learn and develop the skills to be the best in the world at your thing, no matter what it is. Come and knock on our door at Brännnkyrkagatan!", linkText: "Visit Us!",
    link: ADDRESS_LINK,
    target: true,
    animation: reason3,
  },
]
export default reasons
