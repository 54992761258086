import React from "react"
import Lottie from "react-lottie"
import "./ReasonToJoin.css"
import { Link } from "react-router-dom"

//TODO: TODO MOVE TO CONST CONFIGS
const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  },
}
const ReasonToJoin = ({ reason, darkMode }) => {
  return (
    <div className="reason-to-join">
      {/* <Lottie
        style={{
          width: "70%",
          height: "50%",
        }}
        options={{
          ...defaultOptions,
          animationData: reason.animation,
        }}
        height={200}
        width={200}
      /> */}

      {/* <div>
        <video
          // height={200}
          // width={200}
          // loop autoPlay>

          src={reason.animation} type="video /mp4">
          {/* <source src="../../assets/movies/record.mov" type="video/mp4" />
        </video>
      </div> */}
      <p className={` ${darkMode ? "reason-title-dark-mode" : "reason-title"} ${darkMode ? "p-color-white" : "p-color"}`}>
        {reason.name}
      </p>
      <p
        className={`reasons-body  line-height ${
          darkMode ? "p-color-white" : "p-color"
          }`}
      >
        {reason.description}
      </p>

      {
        reason.target === true ? (
          <a className="remove-link-color" href={reason.link} target="_blank">
            <p
              className={`reasons-link font-weight-links line-height ${
                darkMode ? "p-color-white" : "p-color"
                }`}
            >
              {reason.linkText}
            </p>
          </a>
        ) : (
            <Link
              className=" remove-link-color"
              to={{
                pathname: reason.link,
                state: {
                  goToFilter: reason.ref,
                },
              }}
            >
              <p
                className={`reasons-link font-weight-links line-height ${
                  darkMode ? "p-color-white" : "p-color"
                  }`}
              >
                {reason.linkText}
              </p>
            </Link>
          )
      }
    </div >
  )
}

export default ReasonToJoin
