import React from "react"
import "assets/css/about.css"
import { dark } from "@material-ui/core/styles/createPalette"
import reasons from "./reasonsSpec"
import ResonsToJoin from "components/ReasonsToJoin"
import { Blorre } from "components/Blob"
const generalBlobConfig = {
  wrapperContainer: `about-blob-wrapper`,
  addInteraction: false,
  xOffset: 1,
  yOffset: 1,
  width: 200,
  height: 200,

  // color: "#ff6666",

  elasticity: 0.00005,
  acceleration: 0.0003,
  speed: 3,
}

export function AboutPage({ darkMode }) {
  window.scrollTo(0, 0)

  // const blob1Config = {
  //   ...generalBlobConfig,
  //   radiusX: 10,
  //   radiusY: 10,
  //   id: "blob-about-1",
  //   color: "#FF6666",
  // }
  const blob2Config = {
    ...generalBlobConfig,
    width: 300,
    height: 300,
    radiusX: 50,
    radiusY: 50,
    id: "blob-about-2",
    color: darkMode ? "#333366" : "#FFCC33",
  }
  // Blorre(blob1Config, true)
  Blorre(blob2Config, true)
  return (
    <div
      id={"about-blob-wrapper"}
      className={`project-container ${darkMode && "background-dark"}`}
    >
      <div className="header-about-us">
        <p className={`about-us-title ${darkMode && "p-color-white"}`}>
          ABOUT
         {/* <br />
          FUTURE INNOVATIONS */}
        </p>
      </div>
      <div className="body-about-us">
        <div className="header-about-us">
          <p className={`margin-top-40px font-size-32px  about-us-title  ${darkMode && "p-color-white"}`}>
            More than just consultants
          </p>
        </div>
        <p
          className={`p-project-content ${
            darkMode ? "p-color-white" : "p-color"
            }`}
        >
          Growbotics is a Stockholm based innovation hub, and a consultancy firm
          within the fields of AI, cloud engineering and product development. We
          strive to challenge the industry by doing things differently. We
          worship unconventional ideas, passionate humans and new ventures. We
          are a resort for engineers and product designers that aspire to do
          more and elevate from only being code pushers.
          <br />
          <br /> At Growbotics we have something called the Innovation Fund,
          which is a dedicated resource for our employees and outstanding
          innovators in society to get a “Minimum-viable-product”-investment.
          <br />The fund is the core of our beliefs - that a low barrier for trying
          out ideas, is the foundation for creativity.
        </p>
        <ResonsToJoin
          title={"3 Reasons to join"}
          reasons={reasons}
          darkMode={darkMode}
        />
      </div>
    </div>
  )
}

export default AboutPage
