import React, { useState } from "react"
import { IconButton } from "@material-ui/core"
import Project from "components/Project"
import { Icon } from "@iconify/react"
import { green } from 'assets/constants'
import arrowRight from '@iconify/icons-bi/arrow-right';
import arrowLeft from '@iconify/icons-bi/arrow-left';


import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
const CustomCarousel = ({
  currentIndex,
  onChange,
  darkMode,
  projectItems,
  setCurrentIndex,

}) => {
  const [leftArrowHover, setLeftArrowHover] = useState(false)
  const [rightArrowHover, setRightArrowHover] = useState(false)

  const getArrowColor = (hover, arrowDisabled = false) => {
    if (arrowDisabled) {
      return 'grey'
    }
    if (!hover) {
      return `${darkMode ? "white" : "black"}`
    }
    return green
  }
  return (
    <>
      <div className="carousel-wrapper">
        <Carousel
          centered
          arrowLeft={
            <IconButton className="project-arrow project-arrow-left"
              onMouseOver={() => setLeftArrowHover(true)}
              onMouseOut={() => setLeftArrowHover(false)}>

              <Icon
                width="68"
                icon={arrowLeft}
                color={getArrowColor(leftArrowHover)}
              />
            </IconButton>
          }
          arrowLeftDisabled={
            <IconButton
              onMouseOver={() => setLeftArrowHover(true)}
              onMouseOut={() => setLeftArrowHover(false)}
              disableRipple

              className="project-arrow-disabled project-arrow-left">
              <Icon width="68" icon={arrowLeft} color={getArrowColor(leftArrowHover, true)} />
            </IconButton>
          }
          arrowRight={
            <IconButton onMouseOver={() => setRightArrowHover(true)}
              onMouseOut={() => setRightArrowHover(false)} className="project-arrow">
              <Icon

                width="68"
                icon={arrowRight}
                color={getArrowColor(rightArrowHover)}
              />
            </IconButton>
          }
          arrowRightDisabled={
            <IconButton className="project-arrow-disabled"
              onMouseOver={() => setRightArrowHover(true)}
              onMouseOut={() => setRightArrowHover(false)}
              disableRipple
            >
              <Icon width="68" icon={arrowRight} color={getArrowColor(rightArrowHover, true)} />
            </IconButton>
          }
          value={currentIndex}
          onChange={onChange}
          addArrowClickHandler
          slides={projectItems.map((item, index) => (
            <Project darkMode={darkMode} item={item} index={index} />
          ))}
        />
        <div
          className="dots-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomDots
            setIndex={setCurrentIndex}
            darkMode={darkMode}
            array={projectItems}
            selectedIndex={currentIndex}
          />
        </div>
      </div>
      <div className="carousel-wrapper-phone">
        <Carousel
          centered
          value={currentIndex}
          onChange={onChange}
          addArrowClickHandler
          slides={projectItems.map((item, index) => (
            <Project darkMode={darkMode} item={item} index={index} />
          ))}
        />
        <div
          className="dots-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomDots
            setIndex={setCurrentIndex}
            darkMode={darkMode}
            array={projectItems}
            selectedIndex={currentIndex}
          />
        </div>
      </div>
    </>
  )
}
export default CustomCarousel
const CustomDots = ({ darkMode, array, selectedIndex, setIndex }) => (
  <div
    className="project-dots"
    style={{
      display: "flex",
      // border: "solid red",
      height: 32,
      width: "75%",
      maxWidth: 246,
      justifyContent: "space-evenly",
      alignItems: "center",
    }}
  >
    {array.map((_, index) => (
      <div
        style={{
          //   border: "solid green",
          padding: 8,
        }}
        onClick={() => setIndex(index)}
      >
        <div
          style={{
            opacity: index === selectedIndex ? 1.0 : 0.5,
            borderRadius: index === selectedIndex ? "16px" : "20px",
            background: darkMode ? "white" : "black",
            width: index === selectedIndex ? 16 : 8,
            height: index === selectedIndex ? 16 : 8,
          }}
        ></div>
      </div>
    ))}
  </div>
)
