import React, { useEffect, useState } from "react"
import "./Blob.css"
class Blob {
  constructor(config) {
    this.points = []
    this.config = config
  }

  init() {
    for (let i = 0; i < this.numPoints; i++) {
      let point = new Point(this.divisional * (i + 1), this, this.config)
      point.elasticity = this.elasticity
      // point.acceleration = -1 + Math.random() * 2;
      this.push(point)
    }
  }

  render() {
    let canvas = this.canvas
    let ctx = this.ctx
    let position = this.position
    let pointsArray = this.points
    let radiusX = this.radiusX
    let radiusY = this.radiusY
    let color = this.color
    let points = this.numPoints
    let divisional = this.divisional
    let center = this.center
    let elasticity = this.elasticity

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    pointsArray[0].solveWith(pointsArray[points - 1], pointsArray[1])

    let p0 = pointsArray[points - 1].position
    let p1 = pointsArray[0].position
    let _p2 = p1

    ctx.beginPath()
    ctx.moveTo(center.x, center.y)
    ctx.moveTo((p0.x + p1.x) / 2, (p0.y + p1.y) / 2)

    for (let i = 1; i < points; i++) {
      pointsArray[i].solveWith(
        pointsArray[i - 1],
        pointsArray[i + 1] || pointsArray[0]
      )

      let p2 = pointsArray[i].position
      var xc = (p1.x + p2.x) / 2
      var yc = (p1.y + p2.y) / 2
      ctx.quadraticCurveTo(p1.x, p1.y, xc, yc)

      // ctx.lineTo(p2.x, p2.y);

      ctx.fillStyle = color
      ctx.shadowColor = "#000000"
      const blurVar = 0
      ctx.shadowOffsetX = blurVar
      ctx.shadowOffsetY = blurVar

      ctx.shadowBlur = blurVar
      // ctx.fillRect(p1.x-2.5, p1.y-2.5, 5, 5);

      p1 = p2
    }

    var xc = (p1.x + _p2.x) / 2
    var yc = (p1.y + _p2.y) / 2
    ctx.quadraticCurveTo(p1.x, p1.y, xc, yc)
    // ctx.quadraticCurveTo(p1.x + 700, 0, xc - 800, yc + 10)

    // ctx.quadraticCurveTo(p1.x + 100, p1.x, xc - 800, yc + 10)
    // ctx.quadraticCurveTo(p1.x + 100, p1.x, xc + 800, yc - 10)

    // ctx.quadraticCurveTo(p1.x - 200, p1.x - 300, xc - 200, yc)

    // ctx.quadraticCurveTo(80, 10, xc + 100, yc + 10)

    // ctx.quadraticCurveTo(80, 10, xc + 100, yc + 10)

    // ctx.lineTo(_p2.x, _p2.y);

    // ctx.closePath();
    ctx.fillStyle = color
    ctx.fill()
    ctx.strokeStyle = color
    // ctx.stroke();

    /*
    ctx.fillStyle = '#000000';
    if(this.mousePos) {
      let angle = Math.atan2(this.mousePos.y, this.mousePos.x) + Math.PI;
      ctx.fillRect(center.x + Math.cos(angle) * this.radius, center.y + Math.sin(angle) * this.radius, 5, 5);
    }
*/
    requestAnimationFrame(this.render.bind(this))
  }

  push(item) {
    if (item instanceof Point) {
      this.points.push(item)
    }
  }

  set color(value) {
    this._color = value
  }
  get color() {
    return this._color
  }

  set canvas(value) {
    if (
      value instanceof HTMLElement &&
      value.tagName.toLowerCase() === "canvas"
    ) {
      this._canvas = value
      this.ctx = this._canvas.getContext("2d")
    }
  }
  get canvas() {
    return this._canvas
  }

  set numPoints(value) {
    if (value > 2) {
      this._points = value
    }
  }
  get numPoints() {
    return this._points || 32
  }

  set radiusX(value) {
    if (value > 0) {
      this._radiusX = value
    }
  }

  set radiusY(value) {
    if (value > 0) {
      this._radiusY = value
    }
  }
  get radiusX() {
    return this._radiusX || 150
  }
  get radiusY() {
    return this._radiusY || 150
  }

  set position(value) {
    if (typeof value == "object" && value.x && value.y) {
      this._position = value
    }
  }
  get position() {
    return this._position || { x: 0.5, y: 0.5 }
  }

  get divisional() {
    return (Math.PI * 2) / this.numPoints
  }

  get center() {
    return {
      x: this.canvas.width * this.position.x,
      y: this.canvas.height * this.position.y,
    }
  }

  set running(value) {
    this._running = value === true
  }
  get running() {
    return this.running !== false
  }
}

class Point {
  constructor(azimuth, parent, config) {
    this.parent = parent
    this.azimuth = Math.PI - azimuth
    this._components = {
      x: Math.cos(this.azimuth),
      y: Math.sin(this.azimuth),
    }
    this.config = config
    this.acceleration = config.acceleration
      ? config.acceleration
      : 0.002 + Math.random() * 0.06
    // this.speed = 0.002 + Math.random() * 0.06
  }

  solveWith(leftPoint, rightPoint) {
    this.acceleration =
      (-0.3 * this.radialEffect +
        (leftPoint.radialEffect - this.radialEffect) +
        (rightPoint.radialEffect - this.radialEffect)) *
      this.elasticity -
      this.speed * this.friction
    // console.log(this.acceleration)
  }

  set acceleration(value) {
    if (typeof value == "number") {
      if (Math.abs(value) < 0.0001) {
        // console.log("LOOOOWER")
        this.acceleration = 0.002 + Math.random() * 0.06
      } else {
        this._acceleration = value
      }
      // this.speed += this._acceleration * 0.7
      const test = this.config.speed ? this.config.speed : 0.7
      this.speed += this._acceleration * test //5
    }
  }
  get acceleration() {
    return this._acceleration || 0
  }

  set speed(value) {
    if (typeof value == "number") {
      this._speed = value
      this.radialEffect += this._speed * 2
    }
  }
  get speed() {
    return this._speed || 0
  }

  set radialEffect(value) {
    if (typeof value == "number") {
      this._radialEffect = value
    }
  }
  get radialEffect() {
    return this._radialEffect || 0
  }

  get position() {
    return {
      x:
        this.parent.center.x +
        this.components.x * (this.parent.radiusX + this.radialEffect),
      y:
        this.parent.center.y +
        this.components.y * (this.parent.radiusY + this.radialEffect),
    }
  }

  get components() {
    return this._components
  }

  set elasticity(value) {
    if (typeof value === "number") {
      this._elasticity = value
    }
  }
  get elasticity() {
    // return this._elasticity || 0.009
    return this._elasticity || 0.02
  }
  set friction(value) {
    if (typeof value === "number") {
      this._friction = value
    }
  }
  get friction() {
    return this._friction || 0.0085
  }
}

export const Blorre = (config, flip) => {
  const [configColor, setConfigColor] = useState(config.color)
  let blob, canvas
  blob = new Blob(config)
  blob.radiusX = config.radiusX
  blob.radiusY = config.radiusY
  blob.color = config.color
  blob.elasticity = config.elasticity
  blob.acceleration = config.acceleration
  blob.speed = config.speed
  canvas = document.createElement("canvas")
  canvas.className = `canvas ${config.className ? config.className : config.id}`
  canvas.id = `canvas ${config.id}`
  canvas.setAttribute("touch-action", "none")

  //   document.body.appendChild(canvas)

  let divRekt

  let scrollY, originalScrollY
  useEffect(() => {
    canvas.width = config.width ? config.width : 500 //window.innerWidth / 2
    canvas.height = config.height ? config.height : 500 //window.innerHeight / 2
    var currentDivContainter = document.getElementById(config.wrapperContainer)
    divRekt = currentDivContainter.getBoundingClientRect()
    const constExistingCanvas = document.getElementById(`canvas ${config.id}`)
    constExistingCanvas &&
      constExistingCanvas.parentNode.removeChild(constExistingCanvas)
    !document.getElementById(`canvas ${config.id}`) &&
      currentDivContainter &&
      currentDivContainter.appendChild(canvas)
    // console.log("UseEffect", config.id, divRekt)

    originalScrollY = window.scrollY
  }, [flip, config.color])

  //   let resize = function() {
  //     canvas.width = 500 //window.innerWidth / 2
  //     canvas.height = 500 //window.innerHeight / 2
  //   }
  //   window.addEventListener("resize", resize)
  //   resize()

  let oldMousePoint = { x: 0, y: 0 }
  let hover = false
  let diffScroll
  let mouseMove = function (e) {
    // let pos = blob.center
    scrollY = window.scrollY
    diffScroll = scrollY - originalScrollY

    if (divRekt !== null && divRekt !== undefined) {
      let pos = { x: 0, y: 0 }
      pos.x = config.xOffset * divRekt.x
      pos.y = config.yOffset * divRekt.y

      // // if (config.id === "blob-left") {
      // console.log("---------woman-container", divRekt.x, divRekt.y)

      // console.log("Blob: pos c", config.id, pos, e.clientX, e.clientY)
      //   console.log(divRekt.x, divRekt.y)
      //   //   console.log(e.clientX / window.innerWidth)
      //   //   console.log("WIDTH: ", window.innerWidth)
      //   //   console.log("Blob: pos c", config.id, pos)
      //   //   console.log(
      //   //     "Innerwidth",
      //   //     config.xOffset * window.innerWidth,
      //   //     window.innerWidth
      //   //   )
      //   //   console.log("kvot", pos.x / window.innerWidth)

      // console.log(
      //   e
      //   e.clientX,
      //   e.clientY,
      //   "original:",
      //   originalScrollY,
      //   " scrollY",
      //   scrollY,
      //   "diff;",
      //   diffScroll
      // )
      //}
      let diff = { x: e.clientX - pos.x, y: e.clientY + diffScroll - pos.y }
      let dist = Math.sqrt(diff.x * diff.x + diff.y * diff.y)
      let angle = null
      const caclulateScrollDiff = (original, up, diffScroll) =>
        up ? original - diffScroll : original + diffScroll

      const mouseYOffset =
        diffScroll === 0
          ? diffScroll
          : caclulateScrollDiff(
            originalScrollY,
            originalScrollY > scrollY,
            diffScroll
          )
      blob.mousePos = {
        x: pos.x - e.clientX,
        y: pos.y - e.clientY + mouseYOffset,
      }

      if (dist < blob.radiusX && dist < blob.radiusY && hover === false) {
        let vector = {
          x: e.clientX - pos.x,
          y: e.clientY + mouseYOffset - pos.y,
        }
        angle = Math.atan2(vector.y, vector.x)
        hover = true
        // blob.color = "green"
      } else if (dist > blob.radiusX && dist > blob.radiusY && hover === true) {
        let vector = {
          x: e.clientX - pos.x,
          y: e.clientY + mouseYOffset - pos.y,
        }
        angle = Math.atan2(vector.y, vector.x)
        hover = false
        // blob.color = "grey"
      }

      if (typeof angle == "number") {
        let nearestPoint = null
        let distanceFromPoint = 100

        blob.points.forEach((point) => {
          if (Math.abs(angle - point.azimuth) < distanceFromPoint) {
            // console.log(point.azimuth, angle, distanceFromPoint);
            nearestPoint = point
            distanceFromPoint = Math.abs(angle - point.azimuth)
          }
        })

        if (nearestPoint) {
          let strength = {
            x: oldMousePoint.x - e.clientX,
            y: oldMousePoint.y - e.clientY + mouseYOffset,
          }
          strength =
            Math.sqrt(strength.x * strength.x + strength.y * strength.y) * 10
          if (strength > 100) strength = 100
          nearestPoint.acceleration = (strength / 100) * (hover ? -1 : 1)
        }
      }

      oldMousePoint.x = e.clientX
      oldMousePoint.y = e.clientY + mouseYOffset
    }
  }
  // window.addEventListener('mousemove', mouseMove);
  config.addInteraction && window.addEventListener("pointermove", mouseMove)

  blob.canvas = canvas
  blob.init()
  blob.render()
  return blob
  //   return <div>BLOB</div>
}
