import React, { useEffect, useState } from "react"
import "./Project.css"
import { Blorre } from "components/Blob"
import { Icon } from "@iconify/react"
import iosArrowDropdownCircle from "@iconify/icons-ion/ios-arrow-dropdown-circle"

const Project = ({ darkMode, item, index, blobConfig }) => {

  const [totalHeight, setTotalHeight] = useState(0)
  useEffect(() => {
    const wrapperElement = document.getElementById(
      `about-content-wrapper-${index}`
    )
    console.log(wrapperElement)
    setTotalHeight(wrapperElement && wrapperElement.scrollHeight)
    console.log(item.name, totalHeight)
  })
  return (
    <div className="project-slide-wrapper">
      <div key={index} className="project-slide-container">
        <div className="item-name">
          <p
            className={`margin-0 padding-bottom-10px font-weight-900 ${
              darkMode ? "p-color-white" : "p-color-dark"
              }`}
          >
            {item.name}
          </p>
        </div>
        {/* <div className="item-about">
          <p className={`${darkMode ? "p-color-white" : undefined}`}>
            <span
              className={`font-size-16px p-project-bolder${darkMode &&
                "p-color-white"} `}
            >
              Date:
            </span>{" "}
            {item.date}
          </p> 
        </div>
          */}
        <div className="item-about">
          <div>
            <p
              className={` margin-0 ${
                darkMode ? "p-color-white" : "p-color-dark"
                }`}
            >
              <span
                className={`font-size-16px p-project-bolder${
                  darkMode ? "p-color-white" : "p-color-dark"
                  } `}
              >
                About{" "}
                <span style={{ fontStyle: "italic" }}>
                  {item.ref ? `${" "}${item.ref}` : `project`}
                </span>
              </span>
            </p>
          </div>
          <div>
            <div
              id={`about-content-wrapper-${index}`}
              className="about-content-wrapper"
            >
              <div>
                <p
                  className={` p-project-content ${
                    darkMode ? "p-color-white" : "p-color-dark"
                    }`}
                >
                  {item.company}
                </p>
              </div>
              {/* <div className="item-about-sub">
                <p className={`${darkMode ? "p-color-white" : undefined}`}>
                  {item.challenge}
                </p>
              </div> */}
              <div>
                <p
                  className={`p-project-content ${
                    darkMode ? "p-color-white" : "p-color-dark"
                    }`}
                >
                  {item.solution}
                </p>
              </div>
            </div>
            {/* {totalHeight >= 500 && (
              <div className="project-scroll-icon">
                <Icon
                  icon={iosArrowDropdownCircle}
                  color={`${darkMode ? "white" : "black"}`}
                />
              </div>
            )} */}
          </div>
        </div>
      </div>
      {/* <div
        className="project-slide-blob-wrapper  blob-container'"
        id={`blob-project-wrapper-${index}`}
      ></div> */}
    </div>
  )
}

export default Project
