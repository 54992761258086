import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"

import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom"
import "../src/assets/css/index.css"
import { ProjectsPage } from "./components/projects"
import { AboutPage } from "./components/about"
import WorkPage from "./components/work"
import AlternativeHomepage from "./components/home"
import BgBtmLeft from "assets/images/bgBtmLeft.svg"
import BgTopLeft from "assets/images/bgTopLeft.svg"
import BgTopRight from "assets/images/bgTopRight.svg"
import img404 from "assets/images/404.png"

import { DARK_MODE_TIME_DAY, DARK_MODE_TIME_NIGHT } from "assets/constants"


import Logo from "assets/images/Logo.svg"
const withTimeWrapper = (PassedComponent, main = false) => () => {
  const [currentTime, setCurrentTime] = useState(new Date())
  const [darkMode, setDarkMode] = useState(
    currentTime.getHours() >= DARK_MODE_TIME_DAY &&
      currentTime.getHours() < DARK_MODE_TIME_NIGHT
      ? false
      : true
  )

  const getTimeRemaining = (time) => {
    /// darkMode && hours <21
    const today = new Date()
    if (darkMode) {
      if (time.getHours() < DARK_MODE_TIME_NIGHT) {
        return today.setHours(DARK_MODE_TIME_DAY, 0, 0) - time
      } else {
        const tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1)
        tomorrow.setHours(DARK_MODE_TIME_DAY, 0, 0)
        return tomorrow - time
      }
    } else {
      return today.setHours(DARK_MODE_TIME_NIGHT, 0, 0) - time
    }
  }

  const formatTimeRemaining = (time) => {
    const distance = getTimeRemaining(time)
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    return `${hours}h ${minutes}min`
  }

  const [timeLeftToSwitch, setTimeLeftToSwitch] = useState(
    formatTimeRemaining(currentTime)
  )
  useEffect(() => {
    setInterval(() => updateTime(), 1000 * 60)
  }, [])
  const updateTime = () => {
    let newTime = new Date()
    setCurrentTime(newTime)

    if (
      newTime.getHours() >= DARK_MODE_TIME_DAY &&
      newTime.getHours() < DARK_MODE_TIME_NIGHT
    ) {
      setDarkMode(false)
    } else {
      setDarkMode(true)
    }
    setTimeLeftToSwitch(formatTimeRemaining(newTime))
  }
  var htmlElement = document.getElementsByTagName("html")[0]
  htmlElement.style.background = darkMode ? "#222229" : "white"

  return (
    <>
      <PassedComponent darkMode={darkMode} />
      <img src={BgTopLeft} alt="top-left-bg" class="bg-top-left"></img>

      {main && (
        <>
          <img src={BgBtmLeft} alt="btm-left-bg" class="bg-bottom-left"></img>

          <a href="/"></a>
          <img src={BgTopRight} alt="top-right-bg" class="bg-top-right"></img>
          <div className="timer">{timeLeftToSwitch}</div>
        </>
      )}
      <Link style={{ cursor: "pointer" }} to="/">
        <img src={Logo} alt="logo" class="logo" className="logo" />
      </Link>
      {/* <footer className="background-dark">
        <div className="background-dark">
          <p className="p-color-white">ejjo</p>
        </div>
      </footer> */}
    </>
  )
}
const WrappedHome = withTimeWrapper(AlternativeHomepage, true)

const WrappedProjectsPage = withTimeWrapper(ProjectsPage)

const WrappedAbout = withTimeWrapper(AboutPage)
const WrappedWorkPage = withTimeWrapper(WorkPage)

const NotFoundPage = ({ darkMode }) =>
  (
    <div
      className="not-found-page"
    >
      <img className="not-found-image"
        src={img404} alt="404-logo" />
      <p className={darkMode ? "not-found-text-dark-mode" : "not-found-text-light-mode"}>Oops! Sorry boss, page not found</p>
    </div >
  )

const WrappedNotFoundPage = withTimeWrapper(NotFoundPage)

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={WrappedHome} />
      {/* <Route path="/home" component={AlternativeHomepage} /> */}
      <Route path="/projects" component={WrappedProjectsPage} />
      <Route path="/about" component={WrappedAbout} />
      <Route path="/work" component={WrappedWorkPage} />
      <Route path="/404" component={WrappedNotFoundPage} />
      <Redirect to="/404" />
    </Switch>
  </Router>
)




ReactDOM.render(routing, document.getElementById("root"))
