import React from "react"
import animationData from "assets/animations/DeveloperAnimation.json"

const position = {
  name: "A-developer-who-aspires-to-do-more",
  description: style => (
    <p className={style}>
      You would probably define yourself as a Data engineer, Software engineer,
      Machine learning engineer, Backend developer or something similar. You
      have strong opinions about cloud architectures, microservices VS
      serverless, and SQL VS NoSQL. You are painfully aware of the “Cheap, fast,
      good” triangle, and want to have a greater understanding of the business
      and product development as well as understanding what makes a user tick.
      <br />
      As a future startup developer, you are super comfortable with applying the
      right technology to the problem, even if it means that you have to learn a
      mountain of new stuff. You are also pragmatic enough to understand that
      nobody blames you for making errors or not sitting on all the answers, we
      only care about moving forward and evolving as people and as a business.
      <br />
      <br /> Some of the benefits working at Growbotics:
      <br />
      <br />
      20,000 SEK study budget/year
      <br /> Good base salary
      <br /> Opportunity to get shares and ownership in all ideas we are
      developing into startups
      <br /> All usual benefits like 5k health, food discounts etc. etc.
      <br /> 6 weeks vacation (+ 2 weeks for education)
      <br /> We encourage remote work
      <br /> And most importantly -
      <br /> the ability to test your ideas (on work time) in a safe environment
      supported by startup veterans.
      <br />
      <br /> So leave your cushy job and come do something awesome with us
      instead.
    </p>
  ),
  requiredSkillset:
    "AWS, GCP, Azure, Serverless, microservices, Spark, Kafka, Airflow, Flink etc,CI/CD, Terraform, Jenkins, Redis, SQL, NoSQL, Python, Julia, Go, Scala, C/C++, Node.js, Haskell/Clojure, (Tensorflow, MXNet, Sagemaker, AutoML etc)",
  animation: animationData
}

export default position
