import { INVESTMENT, green, red, blue, yellow } from "assets/constants"
const colors = [green, blue, yellow, red]
const items = [
  {
    name: "Marakanda",
    date: "11-10-19",
    // keyWords:
    //   "AWS, SageMaker, Glue, Terraform, API Gateway, GraphQL, S3, Serverless, Athena, Golang, Python, Spark, TensorFlow, ETL, SQL, QuickSight, Node, React, Team lead, Team performance, Business development",
    company:
      "Marakanda is an AI-first startup working in the telecom industry, with the goal of creating a platform that can predict smartphone users' likelihood of upgrading their phone or leaving their operator based on data that can be extracted from the phone itself. ",
    // challenge:
    //   "Growbotics joined Marakanda to be the leading data engineering support for the data science department. This role quickly evolved into a leadership role for both the data science and data engineering team, due to lack of direction. Together with the team, he set a re-aligned everyone to work towards the same goal, established a new best practice for data engineering, re-defined large parts of the cloud infrastructure and then lead the implementation and execution of this work on AWS, utilizing mostly serverless Lambda, Spark and Sagemaker, Redis and a cherry pick of databases.",
    solution:
      "Growbotics joined Markanda as leaders in technology and visionaries for how to develop and operate a petabyte-scale AI organization. Using leadership and technical expertise, we worked with the CXO team setting the roadmap, mentored the team in best practices as well as built a state of the art AI architecture in the cloud. The outcome was a complete change in ways of working as well as tools used to reach the desired goal of having a production ready system that could handle the hundreds of millions of end-users of the service.",
  },
  {
    name: "Young innovation hub ",
    date: "11-10-19",
    //   "Design-designing, Scrum Methodology, Ideation Workshop, Concept Evaluation, Awareness Marketing, Market research/positioning,  Business development, Go-to-market, Product Market Fit, Sales Stratey, Leadership, Vision statement workshop, Business Modeling.",
    company: "Young Innovation Hub is a non-profit organization aiming to educate young leaders and decision makers in society, motivating them to create hubs for entrepreneurship and change-work in Sweden.",
    // challange:
    //   "With a design-thinking method within shareholders and customers along side markets research she enabled Young Innovation Hub to create a go-to-market strategy that auto-utilized their inside and outside resources and delivered a new unique value proposition and stronger product market fit to achieve competitive advantage. Her work also included creating and implementing a marketing and sales strategy with limited resources with a focus on a scrum based growth mindset in the organization.",
    solution: "Growbotics entered the Young Innovation Hub as thought leaders in innovation and communication, with the defined goal to create a better product-market-fit, as well as, to create impact, awareness and growth for their vision, to empower the youth. Using design-thinking methodology, Young Innovation Hub could create a better product-market-fit hat gave larger impact and had a higher competitive advantage.",
  },
  {
    name: "Renapharma",
    date: "11-10-19",
    // keyWords:
    //   "Design-designing, Scrum Methodology, Ideation Workshop, Concept Evaluation, Awareness Marketing, Market research/positioning,  Business development, Go-to-market, Product Market Fit, Sales Stratey, Leadership, Vision statement workshop, Business Modeling.",
    company: "Renapharma is a supplement pharmaceutical company born at Uppsala University Hospital with a vision to modernize and digitalize the Swedish healthcare with innovative products.",    // challange:
    //   "With a design-thinking method within shareholders and customers along side markets research she enabled Young Innovation Hub to create a go-to-market strategy that auto-utilized their inside and outside resources and delivered a new unique value proposition and stronger product market fit to achieve competitive advantage. Her work also included creating and implementing a marketing and sales strategy with limited resources with a focus on a scrum based growth mindset in the organization.",
    solution: "Growbotics joined Renapharma, taking the lead in the digital strategy aswell as helping the company launch new products and increase market shares on the Swedish market. A new operational sales model was also invented and implemented that allowed Renapharma to decrease overall sales cost while still reaching  30 % of the pharmacies in Sweden’s largest cities. To the track the ROI and KPI:s  of the new initiative called called Sales Academy were an realtime dashboard  and portal set-up, that allowed the company to become data driven and aligned.", fill: yellow,
  },
  {
    name: "Urban Oasis",
    date: "11-10-19",
    // keyWords:
    //   "GCP, React, Arduino, ESP32, Python, C/C++, Node.js, Hydroponics, Aquaponics, Monitoring, Terraform, Serverless, Cloud Storage",
    company:
      "Urban Oasis is an indoor farming company focusing on growing vegetables locally to reduce the carbon footprint and increase the harvest cycles, using hydroponics. They are delivering everything from mushrooms to basil to large food stores like Ica and Paradiset.",
    // challenge:
    //   "Growbotics assisted Urban Oasis by building an IoT platform for plant surveillance and to be able to work proactively with improving the yields.",
    solution:
      "Growbotics assisted Urban Oasis by building an IoT platform for plant surveillance and to be able to work proactively with improving the yields. The platform consisted of a React interface connected to a serverless GCP architecture that stored real-time data from the different sensors in the production plant. He also assisted with purchasing and installation of the sensors, as well as, writing the firmware for the sensors in order to make the whole system run smoothly and reliably day after day. ",
  },
  {
    name: "Freya Health",
    date: "11-10-19",
    // keyWords:
    //   "GCP, React Native, NodeJS, Express, Postgres, Docker, SQL, Product development, iOS, Android, React, Go, Stripe, System design, Technical lead ",
    company:
      "Freya is a female health startup, focusing on providing expertise knowledge on women health in a digital world. The product consists of physical medical facilities, a digital doctor via app and a health tracker. The goal is to create a 360 data flow between patient and doctor, making the care much more efficient and focused, as well as working preventative instead of responsive.",
    // challenge:
    //   "Growboitcs joined Freya with the purpose of building a MVP of the future platform in order for the company to quickly be able to start getting a revenue stream. He helped with launching and developing services around an out of the box webRTC solution, such as Stripe payment, calendar bookings and a web interface for medical personnel. The platform was hosted on GCP using Javascript and Google App Engine. ",
    solution:
      "Growbotics joined Freya with the purpose of building a MVP of the future platform in order for the company to quickly be able to start getting a revenue stream. He helped with launching and developing services around an out of the box webRTC solution, such as Stripe payment, calendar bookings and a web interface for medical personnel. The platform was hosted on GCP using Javascript and Google App Engine. Growbotics also helped with establishing best-practices and supported the tech team in how to work with cloud environments, containers, git and systems design.",
  },
  {
    name: "Tracy Trackers AB",
    ref: INVESTMENT,
    date: "11-10-19",
    company:
      "Tracy was founded in 2016 with the goal to develop an end-to-end system for dog owners, with the ability to track and analyze the health of their dogs. The physical product was a dog collar, that would collect and analyze behaviours real-time.",
    solution:
      "Growbotics, together with external parties, developed a technical platform consisting of hardware, firmware, AI and a mobile application that together served as the infrastructure to give the Tracy users day-to-day insights in how their dog was feeling and what they could do to improve its health. Growbotics also lead the business development and user experience work, resulting in taking the company through an accelerator program, getting investments as well as finding product-market-fit and creating an indispensable product every dog owner had to have.",
  },
  {
    name: "AgoyIt",
    ref: INVESTMENT,
    date: "11-10-19",
    company:
      "AgoyIt is a fintech startup challenging the large enterprise software companies in the accounting industry. The company offers smarter workflows and a cloud-based solution that simplifies the work for thousands of accounting consultants across the nordics. ",
    solution:
      "Growbotics entered Agoy at the idea stage co-founding the company with its CEO, establishing the technical architecture, mapping product and user requirements to the overall solution. We created an MVP of the software that raised a funding round of 2 MSEK as well as recruited a larger team which was led to build and deploy the first version of the product to the nordic market. The product was a ready-to-scale SaaS service hosted and managed in the cloud.",
  },
]

export default items.map((obj, index) => ({
  ...obj,
  fill: colors[index % colors.length],
}))
