import React, { useState } from "react"
import "assets/css/work.css"
import Lottie from "react-lottie"
import { Blorre } from "components/Blob"
import position from "./positionSpec"
import { Link } from "react-router-dom"
import { IconButton } from "@material-ui/core"
import { Icon } from "@iconify/react"
import iosArrowDropdownCircle from "@iconify/icons-ion/ios-arrow-dropdown-circle"
import iosArrowDropupCircle from "@iconify/icons-ion/ios-arrow-dropup-circle"
import { EMAIL_MIKAELA } from "assets/constants"

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  },
}
const WorkPage = ({ darkMode }) => {
  window.scrollTo(0, 0)

  // TODO: refactor by making item-about-etc genreal component, it is used in position aswell..
  const ProjectBlob = Blorre(
    {
      width: 470,
      height: 470,
      wrapperContainer: `blob-work-wrapper`,
      addInteraction: false,
      id: "blob-work",
      className: "blob-work",
      xOffset: 1,
      yOffset: 1,
      radiusX: 90,
      radiusY: 75,
      // color: "#ff6666",
      color: "#6FBE95",
      elasticity: 0.00005,
      acceleration: 0.0003,
      speed: 0.01,
    },
    true
  )
  const [openDescription, setOpenDescription] = useState(false)
  return (
    <div className={`project-container ${darkMode && "background-dark"} `}>
      <div className="header-about-us">
        <p
          className={`about-us-title margin-bottom-79px ${
            darkMode && "p-color-white"
            }`}
        >
          WORK AT GROWBOTICS        </p>
      </div>
      <div className="work-container display-flex-row">
        <div>
          <div className="work-content">
            <div
              style={{
                boxShadow: darkMode
                  ? "0px 4px 6px #00000066"
                  : " 0px 4px 6px #0000003d",
              }}
              className="work-content-row"
            >
              <div
                className={`work-content-row-animation
              ${darkMode && "animation-phone-dark-background"}`}
                onClick={() => setOpenDescription(!openDescription)}

              >
                <Lottie
                  style={{
                    // border: "solid red",
                    width: "100%",
                    height: "unset",
                  }}
                  options={{
                    ...defaultOptions,
                    animationData: position.animation,
                  }}
                  height={400}
                  width={400}
                />
              </div>
              <div className="work-content-row-text">
                <div className="item-about">
                  <div className="item-about-sub">
                    <p
                      className={`${
                        darkMode ? "p-color-white" : "p-project-color"
                        }`}
                    >
                      <span
                        className={`font-size-16px p-project-bolder 
                        ${darkMode ? "p-color-white" : "p-project-color"}
                        `}
                      >
                        {position.name}
                      </span>
                    </p>
                  </div>
                  <div className="item-about-sub"></div>

                  <div className="item-about-sub">
                    {position.description(
                      ` ${darkMode ? "p-color-white" : "p-project-color "} 
                      font-weight-300  line-height-position
                      `
                    )}
                    {/* <p
                      className={`${
                        darkMode ? "p-color-white" : "p-project-color"
                      } font-weight-300`}
                    >
                      <span
                        className={`font-size-16px p-project-bolder 
                        ${darkMode ? "p-color-white" : "p-project-color"}
                        `}
                      >
                        Keywords:{" "}
                      </span>{" "}
                      {position.requiredSkillset}
                    </p> */}
                  </div>
                  <div className="item-about-sub-phone">
                    <div className="work-expand">
                      <p className="p-project-color">
                        <span className="font-size-13px p-project-bolder p-project-color-green">
                          {position.name}
                        </span>
                      </p>
                      <IconButton
                        onClick={() => setOpenDescription(!openDescription)}
                      >
                        {openDescription ? (
                          <Icon icon={iosArrowDropupCircle} color="black" />
                        ) : (
                            <Icon icon={iosArrowDropdownCircle} color="black" />
                          )}
                      </IconButton>
                    </div>

                    {openDescription && (
                      <>
                        {/* <p className="p-project-bolder p-project-color">
                          About
                        </p> */}
                        {position.description(
                          "p-project-color font-weight-300 line-height-position"
                        )}
                        {/* <p className="p-project-color font-weight-300 ">
                          <span className="font-size-16px p-project-bolder p-project-color">
                            Keywords:{" "}
                          </span>{" "}
                          {position.requiredSkillset}
                        </p> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="blob-work-wrapper" className="contact-blob">
        <a href={EMAIL_MIKAELA} className="remove-link-decoration">
          <div className="contact-text">
            <p className="p-contact-text ">contact</p>
          </div>
        </a>
      </div>
    </div>
  )
}

export default WorkPage
