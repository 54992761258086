import React, { useCallback } from "react"
import ReactDOM from "react-dom"
import { useSpring, animated as a, interpolate } from "react-spring"
import "./styles.css" // Icon made by Freepik from www.flaticon.com

function Eyes({ id, interpBg, interpEye, interpPupil }) {
  var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  console.log("....", isChrome)
  const [{ st, xy }, set] = useSpring(() => ({ st: 0, xy: [0, 0] }))

  return (
    // <div class="container" onMouseMove={onMove} onScroll={onScroll}>
    // <div id={id}>inter

    isChrome ?
      <CompRender id={id} interPupil={interpPupil} interpBg={interpBg} interpEye={interpEye} />
      : <CompRenderALt id={id} interPupil={interpPupil} interpBg={interpBg} interpEye={interpEye} />


  )
}

export default Eyes


const CompRender = ({ id, interpPupil, interpBg, interpEye }) =>
  <a.svg
    className={`${id} svg-eye`}
    style={{ transform: interpBg, WebkitTransform: interpBg }}
    viewBox="0 0 490 512"
  >
    <a.g id="eye" className={id} transform={interpEye} >
      <circle fill="#67B383" cx="105" cy="104" r="104" />
      <path d="M32.5516247,104.414062 C32.5516247,52.4323881 70.6809824,9.35662037 120.492188,1.64794037 C115.264973,0.835536211 109.915627,0.414062 104.46247,0.414062 C47.0424901,0.414062 0.492188,46.9776706 0.492188,104.414062 C0.492188,161.850453 47.0424901,208.414062 104.46247,208.414062 C109.915627,208.414062 115.264973,207.992588 120.492188,207.180184 C70.6809824,199.471504 32.5516247,156.395736 32.5516247,104.414062 Z" />
      <a.g transform={interpPupil} > fill="#FFFFFF">
    <circle fill="#333031" cx="105" cy="104" r="36" />
        <path d="M83,103.921875 C83,86.402344 95.484375,71.804688 112.042969,68.527344 C109.765625,68.078125 107.410156,67.835938 105,67.835938 C85.070312,67.835938 68.914062,83.992188 68.914062,103.921875 C68.914062,123.851562 85.070312,140.007812 105,140.007812 C107.410156,140.007812 109.765625,139.765625 112.042969,139.316406 C95.484375,136.039062 83,121.441406 83,103.921875 Z" />
      </a.g>
    </a.g>

  </a.svg >


const CompRenderALt = ({ id, interpPupil, interpBg, interpEye }) =>
  <a.svg
    className={`${id} svg-eye`}
    style={{ transform: interpBg, WebkitTransform: interpBg }}
    viewBox="0 0 490 512"
  >
    <a.g id="eye" className={id} transform={interpEye} WebkitTransform={interpEye} >
      <circle fill="#67B383" cx="105" cy="104" r="104" />
      <path d="M32.5516247,104.414062 C32.5516247,52.4323881 70.6809824,9.35662037 120.492188,1.64794037 C115.264973,0.835536211 109.915627,0.414062 104.46247,0.414062 C47.0424901,0.414062 0.492188,46.9776706 0.492188,104.414062 C0.492188,161.850453 47.0424901,208.414062 104.46247,208.414062 C109.915627,208.414062 115.264973,207.992588 120.492188,207.180184 C70.6809824,199.471504 32.5516247,156.395736 32.5516247,104.414062 Z" />
      <a.g transform={interpPupil} WebkitTransform={interpPupil} > fill="#FFFFFF">
  <circle fill="#333031" cx="105" cy="104" r="36" />
        <path d="M83,103.921875 C83,86.402344 95.484375,71.804688 112.042969,68.527344 C109.765625,68.078125 107.410156,67.835938 105,67.835938 C85.070312,67.835938 68.914062,83.992188 68.914062,103.921875 C68.914062,123.851562 85.070312,140.007812 105,140.007812 C107.410156,140.007812 109.765625,139.765625 112.042969,139.316406 C95.484375,136.039062 83,121.441406 83,103.921875 Z" />
      </a.g>
    </a.g>

  </a.svg >

