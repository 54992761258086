import React from "react"
import { ADDRESS_LINK, EMAIL_PETTER, EMAIL_CONTACT, EMAIL_MIKAELA } from "assets/constants"
import LocationCityIcon from "@material-ui/icons/LocationCity"
const Contact = ({ darkMode }) => (
  <div className="contact-alternative">
    <a className="remove-link-decoration" target="_blank" href={ADDRESS_LINK}>
      {" "}
      <LocationCityIcon
        className={`location-icon ${darkMode && "p-color-white"}`}
      />
    </a>
    <a className="remove-link-decoration" target="_blank" href={ADDRESS_LINK}>
      <p
        className={`contact-address-text contact-text-home ${
          darkMode && "p-color-white"
          }`}
      >
        Brännnkyrkagatan 35
      </p>
    </a>
    <p
      className={`contact-text-home contact-delimiter ${
        darkMode && "p-color-white"
        }`}
    >
      |
    </p>
    <div className="contact-emails-container">
      <a
        className="remove-link-decoration"
        target="_blank"
        href={EMAIL_CONTACT}
      >
        <p className={`contact-text-home ${darkMode && "p-color-white"}`}>
          info@growbotics.se
        </p>
      </a>
      {/* <p
        className={` contact-delimiter-email contact-text-home   ${
          darkMode && "p-color-white"
        }`}
      >
        |
      </p>
      <a className="remove-link-decoration" target="_blank" href={EMAIL_PETTER}>
        <p className={`contact-text-home ${darkMode && "p-color-white"}`}>
          petter@growbotics.se
        </p>
      </a> */}
    </div>
  </div>
)
export default Contact
